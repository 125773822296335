import {
  SEND_OTP,
  SEND_OTP_SUCCESSFUL,
  SEND_OTP_FAILED,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESSFUL,
  VERIFY_OTP_FAILED,
} from "./actionTypes";

const initialState = {
  otp: null,
  message: null,
  isvalid: false,
  loading: false,
};

const mobileotp = (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP:
      state = {
        ...state,
        mobile: action.payload.userDetails.mobile,
        email: action.payload.userDetails.email,
        password: action.payload.userDetails.password,
        otp: null,
        loading: true,
        message: null,
        isvalid: false,
      };
      break;
    case SEND_OTP_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        otp: action.payload.data.otp,
        message: null,
        isvalid: false,
      };
      break;
    case SEND_OTP_FAILED:
      state = {
        ...state,
        loading: false,
        otp: null,
        message: action.payload.message,
        isvalid: false,
        ismobilevalid: false,
      };
      break;
    case VERIFY_OTP:
      state = {
        ...state,
        loading: true,
        message: null,
        isvalid: false,
      };
      break;
    case VERIFY_OTP_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        message: null,
        isvalid: true,
        otp: null,
      };
      break;
    case VERIFY_OTP_FAILED:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
        isvalid: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default mobileotp;
