import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import MobileOTP from "./auth/mobileotp/reducer";
import VerifyEmail from "./auth/verifyemail/reducer";

// Forms
import ApplicationForm from "./applicationform/reducer";
// Upload Documents
import UploadDocuments from "./uploaddocument/reducer";

// Enquiry
import Enquiry from "./enquiry/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  MobileOTP,
  VerifyEmail,
  Enquiry,
  //Auth
  ApplicationForm,
  UploadDocuments,
});

export default rootReducer;
