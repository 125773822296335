import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Dropzone from "react-dropzone";
import { AvForm, AvField } from "availity-reactstrap-validation";

// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { withRouter, Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// action
import { uploadDocument } from "../../store/actions";

class DocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    this.showNotification.bind(this);
    this.handleFileUpload.bind(this);
    this.state = { selectedFiles: [], selectedFileChanged: false };
  }

  showNotification() {
    if (this.props.message) {
      if (this.props.message === "Document uploaded successfully.")
        var title = "Success";
      else title = "Error";
      var message = this.props.message;

      toastr.options = {
        positionClass: "toast-top-right",
        timeOut: 5000,
        extendedTimeOut: 1000,
        closeButton: true,
        progressBar: true,
        preventDuplicates: false,
        newestOnTop: true,
        showEasing: "linear",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
        showDuration: 300,
        hideDuration: 1000,
      };

      // setTimeout(() => toastr.success(`Settings updated `), 300)
      //Toaster Types
      if (this.props.message === "Document uploaded successfully.")
        toastr.success(message, title);
      else toastr.error(message, title);
    }
  }

  // handleFileUpload
  handleFileUpload = (event, values) => {
    const file = this.state.selectedFiles ? this.state.selectedFiles[0] : null;
    let formData = new FormData();
    formData.set("document_name", values.document_name);
    // const documentUploadData = {
    //   ...values,
    //   document_file: file,
    // };
    formData.append("document_file", file);
    // for (var key of formData.entries()) {
    //   console.log(key[0] + ", " + key[1]);
    // }

    this.props.uploadDocument(formData);
    this.setState({ selectedFileChanged: false });
  };

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files, selectedFileChanged: true });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  render() {
    if (!this.state.selectedFileChanged && this.props.message) {
      this.showNotification();
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Upload Documents" />

            <Row>
              <Col className="col-6">
                <Card>
                  <CardBody>
                    <Form>
                      <AvForm onValidSubmit={this.handleFileUpload}>
                        <CardTitle>Document Type</CardTitle>
                        <AvField name="document_name" type="select" required>
                          <option value="" label="--Select Document Type--" />
                          <option value="photo" label="Photograph" />
                          <option value="sign" label="Signature" />
                          <option
                            value="highschool_certificate"
                            label="Certificate of High School"
                          />
                          <option
                            value="highschool_marksheet"
                            label="Marks Sheet of High School"
                          />
                          <option
                            value="intermediate_certificate"
                            label="Certificate of Intermediate"
                          />
                          <option
                            value="intermediate_marksheet"
                            label="Marks Sheet of Intermediate"
                          />
                          <option value="aadhaar" label="Aadhaar Card" />
                          <option
                            value="domicile"
                            label="Certificate of Domicile"
                          />
                          <option
                            value="caste_certificate"
                            label="Certificate of Caste"
                          />
                          <option
                            value="income_certificate"
                            label="Certificate of Income"
                          />
                        </AvField>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                        <div className="mt-4">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </AvForm>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { uploads, loading, message } = state.UploadDocuments;
  return {
    uploads,
    loading,
    message,
  };
};
export default withRouter(
  connect(mapStatetoProps, {
    uploadDocument,
  })(DocumentUpload)
);
