import {
  SUBMIT_ENQUIRY,
  SUBMIT_ENQUIRY_SUCCESSFUL,
  SUBMIT_ENQUIRY_FAILED,
  LOAD_PROGRAMS,
  LOAD_PROGRAMS_SUCCESSFUL,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  programs: null,
  message: null,
  loading: false,
};

const enquiryform = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_ENQUIRY:
      state = {
        ...state,
        loading: true,
        message: null,
      };
      break;
    case SUBMIT_ENQUIRY_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: "Enquiry submitted successfully.",
      };
      break;
    case SUBMIT_ENQUIRY_FAILED:
      console.log(action.payload);
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;
    case LOAD_PROGRAMS:
      state = {
        ...state,
      };
      break;
    case LOAD_PROGRAMS_SUCCESSFUL:
      state = {
        ...state,
        programs: action.payload.data,
        message: null,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default enquiryform;
