import React, { Component } from "react";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// actions
import { verifyEmail } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (this.props.location.search.substring(1, 500)) {
      const queryparams = this.props.location.search
        .substring(1, 500)
        .split("&");
      const email = queryparams[0] ? queryparams[0].split("=") : null;
      const id = queryparams[1] ? queryparams[1].split("=") : null;
      this.props.verifyEmail({
        id: id ? id[1] : null,
        email: email ? email[1] : null,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            Online Admission Application
                          </h5>
                          <p>Email Verification</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.isverified ? (
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                          </div>
                          <h5>Email Verified</h5>
                          <p className="text-muted">
                            Thank you for verifying your email address. Please
                            proceed with your application submission.
                          </p>
                        </div>
                      ) : (
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-alert-circle-outline text-success display-4"></i>
                          </div>
                          <h5>Error</h5>
                          <p className="text-muted">
                            Invalid link, contact at{" "}
                            <a
                              href="mailto:help@manyversity.com"
                              className="text-primary"
                            >
                              help@manyversity.com
                            </a>{" "}
                            for help.
                          </p>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} OneBigBit Technologies.
                    <br />
                    Powered by OneBigBit Technologies Private Limited.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { isverified, loading, message } = state.VerifyEmail;
  return { isverified, loading, message };
};

export default withRouter(connect(mapStatetoProps, { verifyEmail })(Login));
