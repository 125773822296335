import { takeEvery, fork, put, all, call } from "redux-saga/effects";
//UploadDocument Redux states
import { UPLOAD_DOCUMENT } from "./actionTypes";
import { uploadDocumentSuccessful, apiError } from "./actions";

//Include Helper Files with needed methods
import { postSubmitForm_withformdata } from "../../helpers/forms_helper";

//Upload Document
function* uploadDocument({ payload: { documentDetails } }) {
  try {
    let url =
      process.env.REACT_APP_BASEURL + "admissionapplicants/uploaddocument";
    const response = yield call(
      postSubmitForm_withformdata,
      url,
      documentDetails
    );
    console.log(response);
    if (response.status === 1) {
      yield put(uploadDocumentSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUploadDocument() {
  yield takeEvery(UPLOAD_DOCUMENT, uploadDocument);
}

function* uploaddocumentSaga() {
  yield all([fork(watchUploadDocument)]);
}

export default uploaddocumentSaga;
