import {
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESSFUL,
  VERIFY_EMAIL_FAILED,
} from "./actionTypes";

export const verifyEmail = (userDetails) => {
  return {
    type: VERIFY_EMAIL,
    payload: { userDetails },
  };
};

export const verifyEmailSuccessful = (userDetails) => {
  return {
    type: VERIFY_EMAIL_SUCCESSFUL,
    payload: userDetails,
  };
};

export const verifyEmailFailed = (userDetails) => {
  return {
    type: VERIFY_EMAIL_FAILED,
    payload: userDetails,
  };
};
