import {
  SEND_OTP,
  SEND_OTP_SUCCESSFUL,
  SEND_OTP_FAILED,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESSFUL,
  VERIFY_OTP_FAILED,
} from "./actionTypes";

export const sendOtp = (userDetails) => {
  return {
    type: SEND_OTP,
    payload: { userDetails },
  };
};

export const sendOtpSuccessful = (userDetails) => {
  return {
    type: SEND_OTP_SUCCESSFUL,
    payload: userDetails,
  };
};

export const sendOtpFailed = (userDetails) => {
  return {
    type: SEND_OTP_FAILED,
    payload: userDetails,
  };
};

export const verifyOtp = (otpDetails, history) => {
  return {
    type: VERIFY_OTP,
    payload: { otpDetails, history },
  };
};

export const verifyOtpSuccessful = (otpDetails) => {
  return {
    type: VERIFY_OTP_SUCCESSFUL,
    payload: otpDetails,
  };
};

export const verifyOtpFailed = (otpDetails) => {
  return {
    type: VERIFY_OTP_FAILED,
    payload: otpDetails,
  };
};
