import React, { Component } from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { submitEnquiry, loadPrograms } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.png";
class Enquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleEnquirySubmit
    this.handleEnquirySubmit = this.handleEnquirySubmit.bind(this);
  }

  componentDidMount() {
    this.props.loadPrograms("");
  }

  // handleRegisterSubmit
  handleEnquirySubmit(event, values) {
    const program_name = this.props.programs.find(
      (program) => program._id === values.program_id
    );
    if (program_name) {
      values = {
        ...values,
        program_name: program_name.name,
        source: "website",
      };
    }
    this.props.submitEnquiry(values);
  }
  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            Online Admission Enquiry
                          </h5>
                          <p>Register your interest or submit your query.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.message ===
                      "Enquiry submitted successfully." ? (
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                          </div>
                          <h5>Query Submitted</h5>
                          <p className="text-muted">
                            Thank you for showing your interest and submitting
                            your details. We will get in touch with you soon to
                            address your queries.
                          </p>
                        </div>
                      ) : (
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleEnquirySubmit}
                        >
                          <div className="form-group">
                            <AvField
                              name="name"
                              label="Name"
                              validate={{
                                required: { value: true },
                                maxLength: { value: 30 },
                              }}
                              placeholder="Enter Name"
                              value={this.props.message ? "" : ""}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="program_id"
                              label="Program  "
                              type="select"
                              required
                            >
                              <option value="" label="--Select Program--" />
                              {this.props.programs ? (
                                this.props.programs.map((program) => (
                                  <option
                                    value={program._id}
                                    label={program.name}
                                  />
                                ))
                              ) : (
                                <option>--No Programs--</option>
                              )}
                            </AvField>
                          </div>
                          <div className="form-group">
                            <AvField
                              name="mobile"
                              label="Mobile"
                              type="number"
                              validate={{
                                required: { value: true },
                                pattern: { value: "^[A-Za-z0-9]+$" },
                                minLength: { value: 10 },
                                maxLength: { value: 10 },
                              }}
                              placeholder="Enter Mobile"
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter Email"
                              type="email"
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="query"
                              label="Query"
                              type="textarea"
                              required
                              placeholder="Write your query"
                            />
                          </div>

                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Submit Query
                            </button>
                          </div>
                        </AvForm>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} OneBigBit Technologies.
                    <br />
                    Powered by OneBigBit Technologies Private Limited.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { programs, message, loading } = state.Enquiry;
  return {
    loading,
    programs,
    message,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    submitEnquiry,
    loadPrograms,
  })(Enquiry)
);
