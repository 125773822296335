import {
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESSFUL,
  VERIFY_EMAIL_FAILED,
} from "./actionTypes";

const initialState = {
  isverified: false,
  loading: false,
};

const verifyemail = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_EMAIL:
      state = {
        ...state,
        loading: true,
        isverified: false,
      };
      break;
    case VERIFY_EMAIL_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        isverified: true,
      };
      break;
    case VERIFY_EMAIL_FAILED:
      state = {
        ...state,
        loading: false,
        otp: null,
        message: action.payload.message,
        isverified: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default verifyemail;
