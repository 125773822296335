import {
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESSFUL,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  uploads: null,
  message: null,
  loading: false,
};

const uploaddocument = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT:
      state = {
        ...state,
        loading: true,
        message: null,
      };
      break;
    case UPLOAD_DOCUMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: "Document uploaded successfully.",
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default uploaddocument;
