import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Enquiry Redux states
import { SUBMIT_ENQUIRY, LOAD_PROGRAMS } from "./actionTypes";
import {
  submitEnquirySuccessful,
  submitEnquiryFailed,
  loadProgramsSuccessful,
  apiError,
} from "./actions";

//Include Helper Files with needed methods
import { postSubmitFormNoAuth } from "../../helpers/forms_helper";

//Submit Enquiry
function* submitEnquiry({ payload: { enquiryDetails } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "enquiries/insert";
    const response = yield call(postSubmitFormNoAuth, url, enquiryDetails);
    if (response.status === 1) {
      yield put(submitEnquirySuccessful(response));
    } else {
      yield put(submitEnquiryFailed(response));
    }
  } catch (error) {
    yield put(submitEnquiryFailed(error));
  }
}

//Load Programs
function* loadPrograms({ payload: { enquiryDetails } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "programs/getall";
    const response = yield call(postSubmitFormNoAuth, url, enquiryDetails);
    if (response.status === 1) {
      yield put(loadProgramsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchSubmitEnquiry() {
  yield takeEvery(SUBMIT_ENQUIRY, submitEnquiry);
}

export function* watchLoadPrograms() {
  yield takeEvery(LOAD_PROGRAMS, loadPrograms);
}

function* enquirySaga() {
  yield all([fork(watchSubmitEnquiry), fork(watchLoadPrograms)]);
}

export default enquirySaga;
