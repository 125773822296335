import {
  SUBMIT_APPLICATION,
  SUBMIT_APPLICATION_SUCCESSFUL,
  SUBMIT_APPLICATION_FAILED,
  PREVIOUS_BUTTON_CLICKED,
  PREVIOUS_BUTTON_CLICKED_SUCCESSFUL,
  PREVIOUS_BUTTON_CLICKED_FAILED,
  LOAD_APPLICATION,
  LOAD_APPLICATION_SUCCESSFUL,
  LOAD_APPLICATION_FAILED,
  APPLY,
  APPLY_SUCCESSFUL,
  APPLY_FAILED,
} from "./actionTypes";

const initialState = {
  activeTab: 1,
  activeTabProgress: 1,
  progressValue: 25,
  subjects: [
    {
      label: "Accountancy",
      value: "Accountancy",
    },
    {
      label: "Agriculture",
      value: "Agriculture",
    },
    {
      label: "Arabic",
      value: "Arabic",
    },
    {
      label: "Arts",
      value: "Arts",
    },
    {
      label: "Assamese",
      value: "Assamese",
    },
    {
      label: "Bengali",
      value: "Bengali",
    },
    {
      label: "Biology",
      value: "Biology",
    },
    {
      label: "Biotechnology",
      value: "Biotechnology",
    },
    {
      label: "Business Studies",
      value: "Business Studies",
    },
    {
      label: "Chemistry",
      value: "Chemistry",
    },
    {
      label: "Chinese",
      value: "Chinese",
    },
    {
      label: "Civics",
      value: "Civics",
    },
    {
      label: "Commerce",
      value: "Commerce",
    },
    {
      label: "Computer Science",
      value: "Computer Science",
    },
    {
      label: "Data Entry Operations",
      value: "Data Entry Operations",
    },
    {
      label: "Drawing",
      value: "Drawing",
    },
    {
      label: "Economics",
      value: "Economics",
    },
    {
      label: "English",
      value: "English",
    },
    {
      label: "Environmental Science",
      value: "Environmental Science",
    },
    {
      label: "French",
      value: "French",
    },
    {
      label: "Geography",
      value: "Geography",
    },
    {
      label: "German",
      value: "German",
    },
    {
      label: "Gujarati",
      value: "Gujarati",
    },
    {
      label: "Hindi",
      value: "Hindi",
    },
    {
      label: "History",
      value: "History",
    },
    {
      label: "Home Science",
      value: "Home Science",
    },
    {
      label: "Indian Culture and Heritage",
      value: "Indian Culture and Heritage",
    },
    {
      label: "Introduction to Law",
      value: "Introduction to Law",
    },
    {
      label: "Kannada",
      value: "Kannada",
    },
    {
      label: "Library and Information Science",
      value: "Library and Information Science",
    },
    {
      label: "Malayalam",
      value: "Malayalam",
    },
    {
      label: "Marathi",
      value: "Marathi",
    },
    {
      label: "Mass Communication",
      value: "Mass Communication",
    },
    {
      label: "Mathematics",
      value: "Mathematics",
    },
    {
      label: "Music",
      value: "Music",
    },
    {
      label: "Nepali",
      value: "Nepali",
    },
    {
      label: "Oriya",
      value: "Oriya",
    },
    {
      label: "Painting",
      value: "Painting",
    },
    {
      label: "Persian",
      value: "Persian",
    },
    {
      label: "Physical Science",
      value: "Physical Science",
    },
    {
      label: "Physics",
      value: "Physics",
    },
    {
      label: "Physiology",
      value: "Physiology",
    },
    {
      label: "Political Science",
      value: "Political Science",
    },
    {
      label: "Psychology",
      value: "Psychology",
    },
    {
      label: "Punjabi",
      value: "Punjabi",
    },
    {
      label: "Sanskrit",
      value: "Sanskrit",
    },
    {
      label: "Science",
      value: "Science",
    },
    {
      label: "Science and Technology",
      value: "Science and Technology",
    },
    {
      label: "Sewing",
      value: "Sewing",
    },
    {
      label: "Social Science",
      value: "Social Science",
    },
    {
      label: "Sociology",
      value: "Sociology",
    },
    {
      label: "Spanish",
      value: "Spanish",
    },
    {
      label: "Tamil",
      value: "Tamil",
    },
    {
      label: "Telugu",
      value: "Telugu",
    },
    {
      label: "Tibetan",
      value: "Tibetan",
    },
    {
      label: "Urdu",
      value: "Urdu",
    },
  ],
  ca_address: "",
  ca_city: "",
  ca_state: "",
  ca_country: "",
  ca_pincode: "",
  pa_address: "",
  pa_city: "",
  pa_state: "",
  pa_country: "",
  pa_pincode: "",
  ref_name: "",
  ref_code: "",
  ref_mobile: "",
  ref_email: "",
  name: "",
  parent_mobile: "",
  gender: "",
  date_of_birth: "",
  nationality: "",
  religion: "",
  category: "",
  father_name: "",
  mother_name: "",
  knowledge_source: "",
  qualifying_exam_status: "",
  qualifications: [],
};

const applicationform = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_APPLICATION:
      state = {
        ...state,
        loading: true,
        message: null,
      };
      break;
    case SUBMIT_APPLICATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        activeTab: action.payload.activeTab + 1,
        activeTabProgress: action.payload.activeTabProgress + 1,
        progressValue: action.payload.progressValue + 25,

        ca_address: action.payload.formData.correspondence_address
          ? action.payload.formData.correspondence_address.address
          : null,
        ca_city: action.payload.formData.correspondence_address
          ? action.payload.formData.correspondence_address.city
          : null,
        ca_state: action.payload.formData.correspondence_address
          ? action.payload.formData.correspondence_address.state
          : null,
        ca_country: action.payload.formData.correspondence_address
          ? action.payload.formData.correspondence_address.country
          : null,
        ca_pincode: action.payload.formData.correspondence_address
          ? action.payload.formData.correspondence_address.pincode
          : null,
        pa_address: action.payload.formData.permanent_address
          ? action.payload.formData.permanent_address.address
          : null,
        pa_city: action.payload.formData.permanent_address
          ? action.payload.formData.permanent_address.city
          : null,
        pa_state: action.payload.formData.permanent_address
          ? action.payload.formData.permanent_address.state
          : null,
        pa_country: action.payload.formData.permanent_address
          ? action.payload.formData.permanent_address.country
          : null,
        pa_pincode: action.payload.formData.permanent_address
          ? action.payload.formData.permanent_address.pincode
          : null,
        ref_name: action.payload.formData.referral
          ? action.payload.formData.referral.name
          : null,
        ref_code: action.payload.formData.referral
          ? action.payload.formData.referral.code
          : null,
        ref_mobile: action.payload.formData.referral
          ? action.payload.formData.referral.mobile
          : null,
        ref_email: action.payload.formData.referral
          ? action.payload.formData.referral.email
          : null,
        name: action.payload.formData.name,
        parent_mobile: action.payload.formData.parent_mobile,

        gender: action.payload.formData.gender,
        date_of_birth: action.payload.formData.date_of_birth.substring(0, 10),
        nationality: action.payload.formData.nationality,
        religion: action.payload.formData.religion,
        category: action.payload.formData.category,
        father_name: action.payload.formData.father_name,
        mother_name: action.payload.formData.mother_name,
        knowledge_source: action.payload.formData.knowledge_source,
        qualifying_exam_status: action.payload.formData.qualifying_exam_status,
        qualifications: action.payload.formData.qualifications
          ? action.payload.formData.qualifications
          : [],
        hs_rollno: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).rollnumber
          : null,
        hs_board: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).board
          : null,
        hs_school: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).school
          : null,
        hs_city: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).city
          : null,
        hs_obtained_marks: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).obtained_marks
          : null,
        hs_max_marks: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).max_marks
          : null,
        hs_percentage: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).percentage
          : null,
        hs_grade: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).grade
          : null,
        hs_passing_year: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).passing_year
          : null,
        hs_subjects: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "High School"
            ).subjects
          : [],
        inter_rollno: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).rollnumber
          : null,
        inter_board: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).board
          : null,
        inter_school: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).school
          : null,
        inter_city: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).city
          : null,
        inter_obtained_marks: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).obtained_marks
          : null,
        inter_max_marks: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).max_marks
          : null,
        inter_percentage: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).percentage
          : null,
        inter_grade: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).grade
          : null,
        inter_passing_year: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).passing_year
          : null,
        inter_subjects: action.payload.formData.qualifications
          ? action.payload.formData.qualifications.find(
              (element) => element.name === "Intermediate"
            ).subjects
          : [],
      };
      break;
    case SUBMIT_APPLICATION_FAILED:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;
    case PREVIOUS_BUTTON_CLICKED:
      state = {
        ...state,
      };
      break;
    case PREVIOUS_BUTTON_CLICKED_SUCCESSFUL:
      state = {
        ...state,
        activeTab: action.payload.formTabData.activeTab,
        activeTabProgress: action.payload.formTabData.activeTabProgress,
        progressValue: action.payload.formTabData.progressValue,
      };
      break;
    case PREVIOUS_BUTTON_CLICKED_FAILED:
      state = {
        ...state,
        message: action.payload.message,
      };
      break;
    case LOAD_APPLICATION:
      state = {
        ...state,
        loading: true,
        message: null,
      };
      break;
    case LOAD_APPLICATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        activeTab: action.payload.applicationData.data.form_tab + 1,
        activeTabProgress: action.payload.applicationData.data.form_tab + 1,
        progressValue: (action.payload.applicationData.data.form_tab + 1) * 25,
        ca_address: action.payload.applicationData.data.correspondence_address
          ? action.payload.applicationData.data.correspondence_address.address
          : null,
        ca_city: action.payload.applicationData.data.correspondence_address
          ? action.payload.applicationData.data.correspondence_address.city
          : null,
        ca_state: action.payload.applicationData.data.correspondence_address
          ? action.payload.applicationData.data.correspondence_address.state
          : null,
        ca_country: action.payload.applicationData.data.correspondence_address
          ? action.payload.applicationData.data.correspondence_address.country
          : null,
        ca_pincode: action.payload.applicationData.data.correspondence_address
          ? action.payload.applicationData.data.correspondence_address.pincode
          : null,
        pa_address: action.payload.applicationData.data.permanent_address
          ? action.payload.applicationData.data.permanent_address.address
          : null,
        pa_city: action.payload.applicationData.data.permanent_address
          ? action.payload.applicationData.data.permanent_address.city
          : null,
        pa_state: action.payload.applicationData.data.permanent_address
          ? action.payload.applicationData.data.permanent_address.state
          : null,
        pa_country: action.payload.applicationData.data.permanent_address
          ? action.payload.applicationData.data.permanent_address.country
          : null,
        pa_pincode: action.payload.applicationData.data.permanent_address
          ? action.payload.applicationData.data.permanent_address.pincode
          : null,
        ref_name: action.payload.applicationData.data.referral
          ? action.payload.applicationData.data.referral.name
          : null,
        ref_code: action.payload.applicationData.data.referral
          ? action.payload.applicationData.data.referral.code
          : null,
        ref_mobile: action.payload.applicationData.data.referral
          ? action.payload.applicationData.data.referral.mobile
          : null,
        ref_email: action.payload.applicationData.data.referral
          ? action.payload.applicationData.data.referral.email
          : null,
        name: action.payload.applicationData.data.name,
        parent_mobile: action.payload.applicationData.data.parent_mobile,

        gender: action.payload.applicationData.data.gender,
        date_of_birth: action.payload.applicationData.data.date_of_birth.substring(
          0,
          10
        ),
        nationality: action.payload.applicationData.data.nationality,
        religion: action.payload.applicationData.data.religion,
        category: action.payload.applicationData.data.category,
        father_name: action.payload.applicationData.data.father_name,
        mother_name: action.payload.applicationData.data.mother_name,
        knowledge_source: action.payload.applicationData.data.knowledge_source,
        qualifying_exam_status:
          action.payload.applicationData.data.qualifying_exam_status,
        qualifications: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications
          : [],
        hs_rollno: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).rollnumber
          : null,
        hs_board: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).board
          : null,
        hs_school: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).school
          : null,
        hs_city: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).city
          : null,
        hs_obtained_marks: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).obtained_marks
          : null,
        hs_max_marks: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).max_marks
          : null,
        hs_percentage: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).percentage
          : null,
        hs_grade: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).grade
          : null,
        hs_passing_year: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).passing_year
          : null,
        hs_subjects: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "High School"
            ).subjects
          : [],
        inter_rollno: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).rollnumber
          : null,
        inter_board: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).board
          : null,
        inter_school: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).school
          : null,
        inter_city: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).city
          : null,
        inter_obtained_marks: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).obtained_marks
          : null,
        inter_max_marks: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).max_marks
          : null,
        inter_percentage: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).percentage
          : null,
        inter_grade: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).grade
          : null,
        inter_passing_year: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).passing_year
          : null,
        inter_subjects: action.payload.applicationData.data.qualifications
          ? action.payload.applicationData.data.qualifications.find(
              (element) => element.name === "Intermediate"
            ).subjects
          : [],
        programs_applied: action.payload.applicationData.data.programs_applied
          ? action.payload.applicationData.data.programs_applied
          : [],
      };
      break;
    case LOAD_APPLICATION_FAILED:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;

    case APPLY:
      state = {
        ...state,
        loading: true,
        message: null,
      };
      break;
    case APPLY_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: "Applied Successfully.",
        programs_applied: action.payload.applicationData.data,
      };
      break;
    case APPLY_FAILED:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default applicationform;
