import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//MobileOTP Redux states
import { SEND_OTP, VERIFY_OTP } from "./actionTypes";
import {
  sendOtpSuccessful,
  sendOtpFailed,
  verifyOtpSuccessful,
  verifyOtpFailed,
} from "./actions";

//Include Helper Files with needed methods
import {
  postSendOtp,
  postVerifyOtp,
  postValidateMobile,
} from "../../../helpers/mobileotp_helper";

import { regiterUser } from "../../../helpers/auth_helper";

//Send OTP
function* sendOtp({ payload: { userDetails } }) {
  try {
    var mobile = { mobile: userDetails.mobile };
    const ismobilevalid = yield* validateMobile(mobile);
    if (
      (!ismobilevalid && userDetails.purpose === "registration") ||
      (ismobilevalid && userDetails.purpose === "login")
    ) {
      let url = process.env.REACT_APP_BASEURL + "admissionapplicants/getotp";
      const response = yield call(postSendOtp, url, mobile);
      yield put(sendOtpSuccessful(response));
    } else if (ismobilevalid && userDetails.purpose === "registration") {
      const response = { message: "Mobile number already registered." };
      yield put(sendOtpFailed(response));
    } else if (!ismobilevalid && userDetails.purpose === "login") {
      const response = { message: "Mobile number is invalid." };
      yield put(sendOtpFailed(response));
    }
  } catch (error) {
    yield put(sendOtpFailed(error));
  }
}

//Verify OTP
function* verifyOtp({ payload: { otpDetails, history } }) {
  try {
    var otpapidata = { mobile: otpDetails.mobile, otp: otpDetails.otp };
    let otpurl =
      process.env.REACT_APP_BASEURL + "admissionapplicants/verifyotp";

    const response = yield call(postVerifyOtp, otpurl, otpapidata);

    if (response.status === 1 && otpDetails.purpose === "registration") {
      const registerapidata = {
        mobile: otpDetails.mobile,
        email: otpDetails.email,
        pwd: otpDetails.pwd,
      };
      let regitrationurl =
        process.env.REACT_APP_BASEURL + "admissionapplicants/signup";
      const regitrationResponse = yield call(
        regiterUser,
        regitrationurl,
        registerapidata
      );

      if (regitrationResponse.status === 1) {
        sessionStorage.setItem("authUser", response.data.mobile);
        sessionStorage.setItem("userMobile", response.data.mobile);
        sessionStorage.setItem("userEmail", response.data.email);
        sessionStorage.setItem("userToken", response.data.token);
        yield put(verifyOtpSuccessful(regitrationResponse));
        history.push("/dashboard");
      } else {
        yield put(verifyOtpFailed(regitrationResponse));
      }
    } else {
      yield put(verifyOtpFailed(response));
    }
  } catch (error) {
    yield put(verifyOtpFailed(error));
  }
}

//Validate Mobile Number
function* validateMobile(mobile) {
  try {
    let url =
      process.env.REACT_APP_BASEURL + "admissionapplicants/ismobileregistered";

    const response = yield call(postValidateMobile, url, mobile);

    if (response.status === 1) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return error.message;
  }
}

export function* watchSendOtp() {
  yield takeEvery(SEND_OTP, sendOtp);
}

export function* watchVerifyOtp() {
  yield takeEvery(VERIFY_OTP, verifyOtp);
}

function* mobileotpSaga() {
  yield all([fork(watchSendOtp), fork(watchVerifyOtp)]);
}

export default mobileotpSaga;
