import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import MobileOTP from "./auth/mobileotp/saga";
import VerifyEmail from "./auth/verifyemail/saga";
import LayoutSaga from "./layout/saga";

//forms
import ApplicationForm from "./applicationform/saga";
//Upload Document
import UploadDocument from "./uploaddocument/saga";

//Enquiry
import Enquiry from "./enquiry/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ForgetSaga(),
    LayoutSaga(),
    MobileOTP(),
    ApplicationForm(),
    Enquiry(),
    VerifyEmail(),
    UploadDocument(),
  ]);
}
