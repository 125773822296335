export const SUBMIT_APPLICATION = "submit_application";
export const SUBMIT_APPLICATION_SUCCESSFUL = "submit_application_successful";
export const SUBMIT_APPLICATION_FAILED = "submit_application_failed";

export const PREVIOUS_BUTTON_CLICKED = "previous_button_clicked";
export const PREVIOUS_BUTTON_CLICKED_SUCCESSFUL =
  "previous_button_clicked_successful";
export const PREVIOUS_BUTTON_CLICKED_FAILED = "previous_button_clicked_failed";

export const LOAD_APPLICATION = "load_application";
export const LOAD_APPLICATION_SUCCESSFUL = "load_application_successful";
export const LOAD_APPLICATION_FAILED = "load_application_failed";

export const APPLY = "apply";
export const APPLY_SUCCESSFUL = "apply_successful";
export const APPLY_FAILED = "apply_failed";
