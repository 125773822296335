import React, { Component } from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  apiError,
  registerUserFailed,
  sendOtp,
  verifyOtp,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.png";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleRegisterSubmit
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);

    // handleOtpSubmit
    this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
  }

  // handleRegisterSubmit
  handleRegisterSubmit(event, values) {
    values = {
      ...values,
      purpose: "registration",
    };
    this.props.sendOtp(values);
  }

  // handleOtpSubmit
  handleOtpSubmit(event, values) {
    values = {
      ...values,
      mobile: this.props.mobile,
      purpose: "registration",
      email: this.props.email,
      pwd: this.props.password,
    };
    this.props.verifyOtp(values, this.props.history);
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            Online Admission Application
                          </h5>
                          <p>Get registered for online application.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              // className="rounded-circle"
                              height="64"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <div className="mb-4 text-center">
                        <p className="mb-0">
                          Already registered?{" "}
                          <Link
                            to="/login"
                            className="font-weight-medium text-primary"
                          >
                            Click to Login
                          </Link>
                        </p>
                      </div>
                      {this.props.user && this.props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}
                      {this.props.registrationError &&
                      this.props.registrationError ? (
                        <Alert color="danger">
                          {this.props.registrationError}
                        </Alert>
                      ) : null}
                      {this.props.message && this.props.message ? (
                        <Alert color="danger">{this.props.message}</Alert>
                      ) : null}

                      {this.props.otp == null ? (
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleRegisterSubmit}
                        >
                          <div className="form-group">
                            <AvField
                              name="mobile"
                              label="Mobile"
                              validate={{
                                required: { value: true },
                                pattern: { value: "^[A-Za-z0-9]+$" },
                                minLength: { value: 10 },
                                maxLength: { value: 10 },
                              }}
                              placeholder="Enter Mobile"
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter Email"
                              type="email"
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="Password"
                              type="password"
                              required
                              placeholder="Set Password"
                            />
                          </div>

                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Register
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <p className="mb-0">
                              By registering you agree to the{" "}
                              <Link to="#" className="text-primary">
                                Terms of Use
                              </Link>
                            </p>
                          </div>
                        </AvForm>
                      ) : null}

                      {this.props.otp && this.props.otp ? (
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleOtpSubmit}
                        >
                          <div className="form-group">
                            <AvField
                              name="otp"
                              label="OTP"
                              validate={{
                                required: { value: true },
                                pattern: { value: "^[A-Za-z0-9]+$" },
                                minLength: { value: 6 },
                                maxLength: { value: 6 },
                              }}
                              placeholder="Enter OTP"
                            />
                          </div>
                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Submit OTP
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <p className="mb-0">
                              By registering you agree to the{" "}
                              <Link to="#" className="text-primary">
                                Terms of Use
                              </Link>
                            </p>
                          </div>
                        </AvForm>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} OneBigBit Technologies.
                    <br />
                    Powered by OneBigBit Technologies Private Limited.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError } = state.Account;
  const {
    email,
    password,
    mobile,
    otp,
    message,
    isvalid,
    loading,
    ismobilevalid,
  } = state.MobileOTP;
  return {
    email,
    password,
    user,
    registrationError,
    loading,
    mobile,
    otp,
    message,
    isvalid,
    ismobilevalid,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    verifyOtp,
    sendOtp,
    registerUser,
    apiError,
    registerUserFailed,
  })(Register)
);
