import {
  SUBMIT_ENQUIRY,
  SUBMIT_ENQUIRY_SUCCESSFUL,
  SUBMIT_ENQUIRY_FAILED,
  LOAD_PROGRAMS,
  LOAD_PROGRAMS_SUCCESSFUL,
  API_ERROR,
} from "./actionTypes";

export const submitEnquiry = (enquiryDetails) => {
  return {
    type: SUBMIT_ENQUIRY,
    payload: { enquiryDetails },
  };
};

export const submitEnquirySuccessful = (enquiryDetails) => {
  return {
    type: SUBMIT_ENQUIRY_SUCCESSFUL,
    payload: enquiryDetails,
  };
};

export const submitEnquiryFailed = (enquiryDetails) => {
  return {
    type: SUBMIT_ENQUIRY_FAILED,
    payload: enquiryDetails,
  };
};

export const loadPrograms = (programs) => {
  return {
    type: LOAD_PROGRAMS,
    payload: { programs },
  };
};

export const loadProgramsSuccessful = (programs) => {
  return {
    type: LOAD_PROGRAMS_SUCCESSFUL,
    payload: programs,
  };
};

export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
