import {
  SUBMIT_APPLICATION,
  SUBMIT_APPLICATION_SUCCESSFUL,
  SUBMIT_APPLICATION_FAILED,
  PREVIOUS_BUTTON_CLICKED,
  PREVIOUS_BUTTON_CLICKED_SUCCESSFUL,
  PREVIOUS_BUTTON_CLICKED_FAILED,
  LOAD_APPLICATION,
  LOAD_APPLICATION_SUCCESSFUL,
  LOAD_APPLICATION_FAILED,
  APPLY,
  APPLY_SUCCESSFUL,
  APPLY_FAILED,
} from "./actionTypes";

export const submitApplication = (applicationData, history) => {
  return {
    type: SUBMIT_APPLICATION,
    payload: { applicationData, history },
  };
};

export const submitApplicationSuccessful = (applicationData) => {
  return {
    type: SUBMIT_APPLICATION_SUCCESSFUL,
    payload: applicationData,
  };
};

export const submitApplicationFailed = (applicationData) => {
  return {
    type: SUBMIT_APPLICATION_FAILED,
    payload: applicationData,
  };
};

export const previousButtonClicked = (formTabData) => {
  return {
    type: PREVIOUS_BUTTON_CLICKED,
    payload: { formTabData },
  };
};

export const previousButtonClickedSuccessful = (formTabData) => {
  return {
    type: PREVIOUS_BUTTON_CLICKED_SUCCESSFUL,
    payload: { formTabData },
  };
};

export const previousButtonClickedFailed = (formTabData) => {
  return {
    type: PREVIOUS_BUTTON_CLICKED_FAILED,
    payload: { formTabData },
  };
};

export const loadApplication = () => {
  return {
    type: LOAD_APPLICATION,
    payload: {},
  };
};

export const loadApplicationSuccessful = (applicationData) => {
  return {
    type: LOAD_APPLICATION_SUCCESSFUL,
    payload: { applicationData },
  };
};

export const loadApplicationFailed = (applicationData) => {
  return {
    type: LOAD_APPLICATION_FAILED,
    payload: applicationData,
  };
};

export const apply = (applicationData, history) => {
  return {
    type: APPLY,
    payload: { applicationData, history },
  };
};

export const applySuccessful = (applicationData) => {
  return {
    type: APPLY_SUCCESSFUL,
    payload: applicationData,
  };
};

export const applyFailed = (applicationData) => {
  return {
    type: APPLY_FAILED,
    payload: applicationData,
  };
};
