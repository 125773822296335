import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  FormGroup,
  Progress,
  Container,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import classnames from "classnames";
import { withRouter, Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// action
import {
  submitApplication,
  previousButtonClicked,
  loadApplication,
  loadPrograms,
  apply,
} from "../../store/actions";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const animatedComponents = makeAnimated();
class ApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubjects: [],
      selectedSubjects_inter: [],
      selectedPrograms: [],
      applicationFee: "",
    };
    this.handleTab1Submit.bind(this);
    this.handleTab2Submit.bind(this);
    this.handleTab3Submit.bind(this);
    this.handleTab4Submit.bind(this);
    this.handleTab5Submit.bind(this);
    this.openPreviousTab.bind(this);
    this.handleSubjectSelect.bind(this);
    this.handleSubjectSelect_Inter.bind(this);
  }
  componentDidMount() {
    this.props.loadApplication();
    this.props.loadPrograms();
  }
  openPreviousTab() {
    console.log(this.props.activeTab);
    if (this.props.activeTab > 1) {
      this.props.previousButtonClicked(this.props.activeTab);
    }
  }

  handleSubjectSelect = (selected) => {
    this.setState({ selectedSubjects: selected });
    // console.log(this.props.hs_subjects);
    // this.setState({
    //   selectedSubjects: {
    //     ...this.props.hs_subjects,
    //     ...this.state.selectedSubjects,
    //     selected,
    //   },
    // });
  };
  handleProgramSelect = (selected) => {
    this.setState({ selectedPrograms: selected }, function () {
      this.setState({
        applicationFee:
          this.state.selectedPrograms &&
          (this.state.selectedPrograms.length > 0
            ? this.state.selectedPrograms.length * 500
            : ""),
      });
    });
  };

  handleSubjectSelect_Inter = (selected) => {
    this.setState({ selectedSubjects_inter: selected });
  };

  // handleTab1Submit
  handleTab1Submit = (event, values) => {
    const applicationFormData = {
      //Tab1 Data
      ...values,
      correspondence_address: {
        address: values.ca_address,
        city: values.ca_city,
        state: values.ca_state,
        country: values.ca_country,
        pincode: values.ca_pincode,
      },
      permanent_address: {
        address: values.pa_address,
        city: values.pa_city,
        state: values.pa_state,
        country: values.pa_country,
        pincode: values.pa_pincode,
      },
      form_tab: this.props.activeTab,

      //Tab2 Data
      qualifying_exam_status: this.props.qualifying_exam_status,
      qualifications: [
        {
          name: "High School",
          rollnumber: this.props.hs_rollno,
          board: this.props.hs_board,
          school: this.props.hs_school,
          city: this.props.hs_city,
          obtained_marks: this.props.hs_obtained_marks,
          max_marks: this.props.hs_max_marks,
          percentage: this.props.hs_percentage,
          grade: this.props.hs_grade,
          passing_year: this.props.hs_passing_year,
          subjects: this.props.hs_subjects,
        },
        {
          name: "Intermediate",
          rollnumber: this.props.inter_rollno,
          board: this.props.inter_board,
          school: this.props.inter_school,
          city: this.props.inter_city,
          obtained_marks: this.props.inter_obtained_marks,
          max_marks: this.props.inter_max_marks,
          percentage: this.props.inter_percentage,
          grade: this.props.inter_grade,
          passing_year: this.props.inter_passing_year,
          subjects: this.props.inter_subjects,
        },
      ],

      //Tab3 Data
      knowledge_source: this.props.knowledge_source,
      referral: {
        name: this.props.ref_name,
        code: this.props.ref_code,
        mobile: this.props.ref_mobile,
        email: this.props.ref_email,
      },
    };

    this.props.submitApplication(applicationFormData);
  };

  // handleTab2Submit
  handleTab2Submit = (event, values) => {
    const applicationFormData = {
      form_tab: this.props.activeTab,

      //Tab1 Data
      name: this.props.name,
      gender: this.props.gender,
      date_of_birth: this.props.date_of_birth,
      nationality: this.props.nationality,
      religion: this.props.religion,
      category: this.props.category,
      father_name: this.props.father_name,
      mother_name: this.props.mother_name,
      parent_mobile: this.props.parent_mobile,
      correspondence_address: {
        address: this.props.ca_address,
        city: this.props.ca_city,
        state: this.props.ca_state,
        country: this.props.ca_country,
        pincode: this.props.ca_pincode,
      },
      permanent_address: {
        address: this.props.pa_address,
        city: this.props.pa_city,
        state: this.props.pa_state,
        country: this.props.pa_country,
        pincode: this.props.pa_pincode,
      },

      //Tab2 Data
      qualifying_exam_status: values.qualifying_exam_status,
      qualifications: [
        {
          name: "High School",
          rollnumber: values.hs_rollno,
          board: values.hs_board,
          school: values.hs_school,
          city: values.hs_city,
          obtained_marks: values.hs_obtained_marks,
          max_marks: values.hs_max_marks,
          percentage: values.hs_percentage,
          grade: values.hs_grade,
          passing_year: values.hs_passing_year,
          subjects: this.state.selectedSubjects,
        },
        {
          name: "Intermediate",
          rollnumber: values.inter_rollno,
          board: values.inter_board,
          school: values.inter_school,
          city: values.inter_city,
          obtained_marks: values.inter_obtained_marks,
          max_marks: values.inter_max_marks,
          percentage: values.inter_percentage,
          grade: values.inter_grade,
          passing_year: values.inter_passing_year,
          subjects: this.state.selectedSubjects_inter,
        },
      ],

      //Tab3 Data
      knowledge_source: this.props.knowledge_source,
      referral: {
        name: this.props.ref_name,
        code: this.props.ref_code,
        mobile: this.props.ref_mobile,
        email: this.props.ref_email,
      },
    };

    this.props.submitApplication(applicationFormData);
  };

  // handleTab3Submit
  handleTab3Submit = (event, values) => {
    const applicationFormData = {
      form_tab: this.props.activeTab,

      //Tab1 Data
      name: this.props.name,
      gender: this.props.gender,
      date_of_birth: this.props.date_of_birth,
      nationality: this.props.nationality,
      religion: this.props.religion,
      category: this.props.category,
      father_name: this.props.father_name,
      mother_name: this.props.mother_name,
      parent_mobile: this.props.parent_mobile,
      correspondence_address: {
        address: this.props.ca_address,
        city: this.props.ca_city,
        state: this.props.ca_state,
        country: this.props.ca_country,
        pincode: this.props.ca_pincode,
      },
      permanent_address: {
        address: this.props.pa_address,
        city: this.props.pa_city,
        state: this.props.pa_state,
        country: this.props.pa_country,
        pincode: this.props.pa_pincode,
      },

      //Tab2 Data
      //Tab2 Data
      qualifying_exam_status: this.props.qualifying_exam_status,
      qualifications: [
        {
          name: "High School",
          rollnumber: this.props.hs_rollno,
          board: this.props.hs_board,
          school: this.props.hs_school,
          city: this.props.hs_city,
          obtained_marks: this.props.hs_obtained_marks,
          max_marks: this.props.hs_max_marks,
          percentage: this.props.hs_percentage,
          grade: this.props.hs_grade,
          passing_year: this.props.hs_passing_year,
          subjects: this.props.hs_subjects,
        },
        {
          name: "Intermediate",
          rollnumber: this.props.inter_rollno,
          board: this.props.inter_board,
          school: this.props.inter_school,
          city: this.props.inter_city,
          obtained_marks: this.props.inter_obtained_marks,
          max_marks: this.props.inter_max_marks,
          percentage: this.props.inter_percentage,
          grade: this.props.inter_grade,
          passing_year: this.props.inter_passing_year,
          subjects: this.props.inter_subjects,
        },
      ],

      //Tab3 Data
      knowledge_source: values.knowledge_source,
      referral: {
        name: values.referralname,
        code: values.code,
        mobile: values.mobile,
        email: values.email,
      },
    };
    this.props.submitApplication(applicationFormData);
  };

  // handleTab4Submit
  handleTab4Submit = (event, values) => {
    const applicationFormData = {
      form_tab: this.props.activeTab,

      //Tab1 Data
      name: this.props.name,
      gender: this.props.gender,
      date_of_birth: this.props.date_of_birth,
      nationality: this.props.nationality,
      religion: this.props.religion,
      category: this.props.category,
      father_name: this.props.father_name,
      mother_name: this.props.mother_name,
      parent_mobile: this.props.parent_mobile,
      correspondence_address: {
        address: this.props.ca_address,
        city: this.props.ca_city,
        state: this.props.ca_state,
        country: this.props.ca_country,
        pincode: this.props.ca_pincode,
      },
      permanent_address: {
        address: this.props.pa_address,
        city: this.props.pa_city,
        state: this.props.pa_state,
        country: this.props.pa_country,
        pincode: this.props.pa_pincode,
      },

      //Tab2 Data
      qualifying_exam_status: this.props.qualifying_exam_status,
      qualifications: [
        {
          name: "High School",
          rollnumber: this.props.hs_rollno,
          board: this.props.hs_board,
          school: this.props.hs_school,
          city: this.props.hs_city,
          obtained_marks: this.props.hs_obtained_marks,
          max_marks: this.props.hs_max_marks,
          percentage: this.props.hs_percentage,
          grade: this.props.hs_grade,
          passing_year: this.props.hs_passing_year,
          subjects: this.props.hs_subjects,
        },
        {
          name: "Intermediate",
          rollnumber: this.props.inter_rollno,
          board: this.props.inter_board,
          school: this.props.inter_school,
          city: this.props.inter_city,
          obtained_marks: this.props.inter_obtained_marks,
          max_marks: this.props.inter_max_marks,
          percentage: this.props.inter_percentage,
          grade: this.props.inter_grade,
          passing_year: this.props.inter_passing_year,
          subjects: this.props.inter_subjects,
        },
      ],

      //Tab3 Data
      knowledge_source: this.props.knowledge_source,
      referral: {
        name: this.props.ref_name,
        code: this.props.ref_code,
        mobile: this.props.ref_mobile,
        email: this.props.ref_email,
      },
    };
    this.props.submitApplication(applicationFormData, this.props.history);
  };

  // handleTab5Submit
  handleTab5Submit = () => {
    let applicationFormData = [];
    if (this.state.selectedPrograms && this.state.selectedPrograms.length > 0) {
      for (let i = 0; i < this.state.selectedPrograms.length; i++) {
        applicationFormData.push({
          program_id: this.state.selectedPrograms[i]._id,
          program_name: this.state.selectedPrograms[i].name,
          transaction_id: "XXXXXXXXXXXXXX",
          amount: 500,
        });
      }
      this.props.apply({ programs: applicationFormData });
    }
  };

  getPrograms = () => {
    let programs = this.props.programs && this.props.programs;
    let applied_programs =
      this.props.programs_applied && this.props.programs_applied;
    programs &&
      programs.slice().forEach((program) => {
        program.value = program._id;
        program.label = program.name;
      });
    if (applied_programs && programs) {
      for (var i = 0, len = applied_programs.length; i < len; i++) {
        for (var j = 0, len2 = programs.length; j < len2; j++) {
          if (programs[j]._id === applied_programs[i].program_id) {
            programs.splice(j, 1);
            len2 = programs.length;
          }
          programs[j].value = programs[j]._id;
          programs[j].label = programs[j].name;
        }
      }
    }
    return programs;
  };
  render() {
    const selectedSubjects = this.props.hs_subjects && this.props.hs_subjects;
    const selectedSubjects_inter =
      this.props.inter_subjects && this.props.inter_subjects;
    const programs = this.getPrograms();

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Application Form" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.props.activeTabProgress === 1,
                            })}
                          >
                            <span className="step-number mr-2">01</span>
                            Personal
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.props.activeTabProgress === 2,
                            })}
                          >
                            <span className="step-number mr-2">02</span>
                            <span>Qualification</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.props.activeTabProgress === 3,
                            })}
                          >
                            <span className="step-number mr-2">03</span>
                            Miscellaneous
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.props.activeTabProgress === 4,
                            })}
                          >
                            <span className="step-number mr-2">04</span>
                            Declaration
                          </NavLink>
                        </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.props.progressValue}
                        />
                        <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                      </div>
                      <TabContent
                        activeTab={this.props.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col>
                                <Card>
                                  <CardBody>
                                    <AvForm
                                      onValidSubmit={this.handleTab1Submit}
                                    >
                                      <br />
                                      <CardTitle>Personal Details:</CardTitle>
                                      <hr />
                                      <Row>
                                        <Col lg="6">
                                          <AvField
                                            name="name"
                                            label="Name  "
                                            placeholder="Enter Name"
                                            type="text"
                                            errorMessage="Enter Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.name}
                                          />

                                          <AvField
                                            name="date_of_birth"
                                            type="date"
                                            label="Date of Birth"
                                            placeholder="Select Date of Birth"
                                            errorMessage="Select Date of Birth"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.date_of_birth}
                                          />
                                          <AvField
                                            name="religion"
                                            label="Religion  "
                                            type="select"
                                            required
                                            value={this.props.religion}
                                          >
                                            <option
                                              value=""
                                              label="--Select Religion--"
                                            />
                                            <option
                                              value="Hinduism"
                                              label="Hinduism"
                                            />
                                            <option
                                              value="Islam"
                                              label="Islam"
                                            />
                                            <option
                                              value="Sikhism"
                                              label="Sikhism"
                                            />
                                            <option
                                              value="Christianity"
                                              label="Christianity"
                                            />
                                            <option
                                              value="Buddhism"
                                              label="Buddhism"
                                            />
                                            <option
                                              value="Jainism"
                                              label="Jainism"
                                            />
                                            <option
                                              value="Zorastrianism"
                                              label="Zorastrianism"
                                            />
                                            <option
                                              value="Judaism"
                                              label="Judaism"
                                            />
                                            <option
                                              value="Other"
                                              label="Other"
                                            />
                                          </AvField>
                                          <AvField
                                            name="father_name"
                                            label="Father's Name"
                                            placeholder="Enter Father's Name"
                                            type="text"
                                            errorMessage="Enter Father's Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.father_name}
                                          />
                                          <AvField
                                            name="parent_mobile"
                                            label="Parent's Mobile"
                                            placeholder="Enter Parent's Mobile Number"
                                            type="number"
                                            errorMessage="Enter Parent's Mobile Number"
                                            validate={{
                                              required: { value: true },
                                              pattern: {
                                                value: "^[A-Za-z0-9]+$",
                                              },
                                              minLength: { value: 10 },
                                              maxLength: { value: 10 },
                                            }}
                                            value={this.props.parent_mobile}
                                          />
                                        </Col>
                                        <Col lg="6">
                                          {/* <label for="gender" class="">
                                            Gender{" "}
                                          </label>
                                          <AvRadioGroup
                                            inline
                                            name="gender"
                                            required
                                          >
                                            <AvRadio
                                              label="Male"
                                              value="Male"
                                            />
                                            <AvRadio
                                              label="Female"
                                              value="Female"
                                            />
                                            <AvRadio
                                              label="Transgender"
                                              value="Transgender"
                                            />
                                          </AvRadioGroup> */}
                                          <AvField
                                            name="gender"
                                            label="Gender  "
                                            type="select"
                                            required
                                            value={this.props.gender}
                                          >
                                            <option>--Select Gender--</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                            <option>Transgender</option>
                                          </AvField>
                                          <AvField
                                            name="nationality"
                                            label="Nationality  "
                                            type="select"
                                            value={this.props.nationality}
                                          >
                                            <option>Indian</option>
                                          </AvField>
                                          <AvField
                                            name="category"
                                            label="Category  "
                                            type="select"
                                            value={this.props.category}
                                          >
                                            <option>--Select Category--</option>
                                            <option>General</option>
                                            <option>SC</option>
                                            <option>ST</option>
                                            <option>OBC</option>
                                          </AvField>
                                          <AvField
                                            name="mother_name"
                                            label="Mother's Name"
                                            placeholder="Enter Mother's Name"
                                            type="text"
                                            errorMessage="Enter Mother's Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.mother_name}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <CardTitle>
                                        Correspondence Address:
                                      </CardTitle>
                                      <hr />
                                      <Row>
                                        <Col lg="6">
                                          <AvField
                                            name="ca_address"
                                            label="Address  "
                                            placeholder="Enter Address"
                                            type="text"
                                            errorMessage="Enter Address"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.ca_address}
                                          />
                                          <AvField
                                            name="ca_state"
                                            label="State  "
                                            placeholder="Enter State"
                                            type="text"
                                            errorMessage="Enter State"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.ca_state}
                                          />
                                          <AvField
                                            name="ca_country"
                                            label="Country  "
                                            placeholder="Enter Country"
                                            type="text"
                                            value="India"
                                            errorMessage="Enter Country"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.ca_country}
                                          />
                                        </Col>
                                        <Col lg="6">
                                          <AvField
                                            name="ca_city"
                                            label="City  "
                                            placeholder="Enter City"
                                            type="text"
                                            errorMessage="Enter City"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.ca_city}
                                          />

                                          <AvField
                                            name="ca_pincode"
                                            label="Pincode  "
                                            placeholder="Enter Pincode"
                                            type="text"
                                            errorMessage="Enter Pincode"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.ca_pincode}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <CardTitle>Permanent Address:</CardTitle>
                                      <hr />
                                      <Row>
                                        <Col lg="6">
                                          <AvField
                                            name="pa_address"
                                            label="Address  "
                                            placeholder="Enter Address"
                                            type="text"
                                            errorMessage="Enter Address"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.pa_address}
                                          />
                                          <AvField
                                            name="pa_state"
                                            label="State  "
                                            placeholder="Enter State"
                                            type="text"
                                            errorMessage="Enter State"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.pa_state}
                                          />
                                          <AvField
                                            name="pa_country"
                                            label="Country  "
                                            placeholder="Enter Country"
                                            type="text"
                                            value="India"
                                            errorMessage="Enter Country"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.pa_country}
                                          />
                                        </Col>
                                        <Col lg="6">
                                          <AvField
                                            name="pa_city"
                                            label="City  "
                                            placeholder="Enter City"
                                            type="text"
                                            errorMessage="Enter City"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.pa_city}
                                          />

                                          <AvField
                                            name="pa_pincode"
                                            label="Pincode  "
                                            placeholder="Enter Pincode"
                                            type="text"
                                            errorMessage="Enter Pincode"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={this.props.pa_pincode}
                                          />
                                        </Col>
                                      </Row>
                                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                                        <li
                                          className={
                                            this.props.activeTabProgress === 1
                                              ? "previous disabled"
                                              : "previous"
                                          }
                                        >
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              this.openPreviousTab();
                                            }}
                                          >
                                            Previous
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            this.props.activeTabProgress === 4
                                              ? "next disabled"
                                              : "next"
                                          }
                                        >
                                          <button
                                            className="btn btn-primary btn-block waves-effect waves-light"
                                            type="submit"
                                          >
                                            Save & Next
                                          </button>
                                        </li>
                                      </ul>
                                    </AvForm>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <AvForm onValidSubmit={this.handleTab2Submit}>
                              <Row>
                                <Col lg="6">
                                  <label for="qualifying_exam_status" class="">
                                    Qualifying Exam Status{" "}
                                  </label>
                                  <AvRadioGroup
                                    inline
                                    name="qualifying_exam_status"
                                    required
                                    value={this.props.qualifying_exam_status}
                                  >
                                    <AvRadio
                                      label="Appearing"
                                      value="Appearing"
                                    />
                                    <AvRadio label="Passed" value="Passed" />
                                  </AvRadioGroup>
                                </Col>
                              </Row>
                              <br />
                              <CardTitle>High School Details:</CardTitle>
                              <hr />
                              <Row>
                                <Col lg="6">
                                  <AvField
                                    name="hs_rollno"
                                    label="Roll Number  "
                                    placeholder="Enter Roll Number"
                                    type="number"
                                    errorMessage="Enter Roll Number"
                                    validate={{
                                      required: { value: true },
                                      pattern: { value: "^[A-Za-z0-9]+$" },
                                      minLength: { value: 6 },
                                      maxLength: { value: 15 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).rollnumber
                                        : null
                                    }
                                  />

                                  <AvField
                                    name="hs_school"
                                    label="School"
                                    placeholder="Enter School Name"
                                    type="text"
                                    errorMessage="Enter School Name (Max. 60 Characters)"
                                    validate={{
                                      required: { value: true },
                                      maxLength: { value: 60 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).school
                                        : null
                                    }
                                  />

                                  <AvField
                                    name="hs_obtained_marks"
                                    label="Obtained Marks"
                                    placeholder="Enter Obtained Marks"
                                    type="number"
                                    errorMessage="Enter Obtained Marks (3 Digits Only)"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[A-Za-z0-9]+$",
                                      },
                                      minLength: { value: 3 },
                                      maxLength: { value: 3 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).obtained_marks
                                        : null
                                    }
                                  />

                                  <AvField
                                    name="hs_percentage"
                                    label="Percentage"
                                    placeholder="Enter Percentage"
                                    type="text"
                                    errorMessage="Enter Percentage  (Upto 2 Decimals)"
                                    validate={{
                                      required: { value: true },
                                      minLength: { value: 5 },
                                      maxLength: { value: 5 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).percentage
                                        : null
                                    }
                                  />

                                  <FormGroup className="mb-0 templating-select select2-container">
                                    <label className="control-label">
                                      Subjects
                                    </label>
                                    <Select
                                      value={selectedSubjects}
                                      isMulti={true}
                                      onChange={this.handleSubjectSelect}
                                      options={this.props.subjects}
                                      classNamePrefix="select2-selection"
                                      closeMenuOnSelect={false}
                                      components={animatedComponents}
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <AvField
                                    name="hs_board"
                                    label="Board"
                                    placeholder="Enter Board Name"
                                    errorMessage="Enter Board Name (Max. 50 Characters)"
                                    validate={{
                                      required: { value: true },
                                      maxLength: { value: 50 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).board
                                        : null
                                    }
                                  />
                                  <AvField
                                    name="hs_city"
                                    label="City"
                                    placeholder="Enter City"
                                    type="text"
                                    errorMessage="Enter City (Max. 30 Characters)"
                                    validate={{
                                      required: { value: true },
                                      maxLength: { value: 30 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).city
                                        : null
                                    }
                                  />
                                  <AvField
                                    name="hs_max_marks"
                                    label="Max Marks"
                                    placeholder="Enter Max Marks"
                                    type="number"
                                    errorMessage="Enter Max Marks (3 Digits Only)"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[A-Za-z0-9]+$",
                                      },
                                      minLength: { value: 3 },
                                      maxLength: { value: 3 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).max_marks
                                        : null
                                    }
                                  />
                                  <AvField
                                    name="hs_grade"
                                    label="Grade"
                                    placeholder="Enter Grade"
                                    type="decimal"
                                    errorMessage="Enter Grade"
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).grade
                                        : null
                                    }
                                  />
                                  <AvField
                                    name="hs_passing_year"
                                    label="Passing Year"
                                    placeholder="Enter Passing Year"
                                    type="number"
                                    errorMessage="Enter Passing Year"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[A-Za-z0-9]+$",
                                      },
                                      minLength: { value: 4 },
                                      maxLength: { value: 4 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "High School"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "High School"
                                          ).passing_year
                                        : null
                                    }
                                  />
                                </Col>
                              </Row>
                              <br />
                              <CardTitle>Intermediate Details:</CardTitle>
                              <hr />
                              <Row>
                                <Col lg="6">
                                  <AvField
                                    name="inter_rollno"
                                    label="Roll Number  "
                                    placeholder="Enter Roll Number"
                                    type="number"
                                    errorMessage="Enter Roll Number"
                                    validate={{
                                      pattern: {
                                        value: "^[A-Za-z0-9]+$",
                                      },
                                      minLength: { value: 6 },
                                      maxLength: { value: 15 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).rollnumber
                                        : null
                                    }
                                  />

                                  <AvField
                                    name="inter_school"
                                    label="School"
                                    placeholder="Enter School Name"
                                    type="text"
                                    errorMessage="Enter School Name (Max. 60 Characters)"
                                    validate={{
                                      maxLength: { value: 60 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).school
                                        : null
                                    }
                                  />

                                  <AvField
                                    name="inter_obtained_marks"
                                    label="Obtained Marks"
                                    placeholder="Enter Obtained Marks"
                                    type="number"
                                    errorMessage="Enter Obtained Marks (3 Digits Only)"
                                    validate={{
                                      pattern: {
                                        value: "^[A-Za-z0-9]+$",
                                      },
                                      minLength: { value: 3 },
                                      maxLength: { value: 3 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).obtained_marks
                                        : null
                                    }
                                  />

                                  <AvField
                                    name="inter_percentage"
                                    label="Percentage"
                                    placeholder="Enter Percentage"
                                    type="decimal"
                                    errorMessage="Enter Percentage (Upto 2 Decimals Only)"
                                    validate={{
                                      minLength: { value: 5 },
                                      maxLength: { value: 5 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).percentage
                                        : null
                                    }
                                  />

                                  <FormGroup className="mb-0 templating-select select2-container">
                                    <label className="control-label">
                                      Subjects
                                    </label>
                                    <Select
                                      value={selectedSubjects_inter}
                                      isMulti={true}
                                      onChange={this.handleSubjectSelect_Inter}
                                      options={this.props.subjects}
                                      classNamePrefix="select2-selection"
                                      closeMenuOnSelect={false}
                                      components={animatedComponents}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <AvField
                                    name="inter_board"
                                    label="Board"
                                    placeholder="Enter Board Name"
                                    errorMessage="Enter Board Name (Max. 50 Characters)"
                                    validate={{
                                      maxLength: { value: 50 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).board
                                        : null
                                    }
                                  />
                                  <AvField
                                    name="inter_city"
                                    label="City"
                                    placeholder="Enter City"
                                    type="text"
                                    errorMessage="Enter City (Max. 30 Characters)"
                                    validate={{
                                      maxLength: { value: 30 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).city
                                        : null
                                    }
                                  />
                                  <AvField
                                    name="inter_max_marks"
                                    label="Max Marks"
                                    placeholder="Enter Max Marks"
                                    type="number"
                                    errorMessage="Enter Max Marks (3 Digits Only)"
                                    validate={{
                                      pattern: {
                                        value: "^[A-Za-z0-9]+$",
                                      },
                                      minLength: { value: 3 },
                                      maxLength: { value: 3 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).max_marks
                                        : null
                                    }
                                  />
                                  <AvField
                                    name="inter_grade"
                                    label="Grade"
                                    placeholder="Enter Grade"
                                    type="decimal"
                                    errorMessage="Enter Grade"
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).grade
                                        : null
                                    }
                                  />
                                  <AvField
                                    name="inter_passing_year"
                                    label="Passing Year"
                                    placeholder="Enter Passing Year"
                                    type="number"
                                    errorMessage="Enter Passing Year"
                                    validate={{
                                      pattern: {
                                        value: "^[A-Za-z0-9]+$",
                                      },
                                      minLength: { value: 4 },
                                      maxLength: { value: 4 },
                                    }}
                                    value={
                                      this.props.qualifications.find(
                                        (element) =>
                                          element.name === "Intermediate"
                                      )
                                        ? this.props.qualifications.find(
                                            (element) =>
                                              element.name === "Intermediate"
                                          ).passing_year
                                        : null
                                    }
                                  />
                                </Col>
                              </Row>
                              <ul className="pager wizard twitter-bs-wizard-pager-link">
                                <li
                                  className={
                                    this.props.activeTabProgress === 1
                                      ? "previous disabled"
                                      : "previous"
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      this.openPreviousTab();
                                    }}
                                  >
                                    Previous
                                  </Link>
                                </li>
                                <li
                                  className={
                                    this.props.activeTabProgress === 4
                                      ? "next disabled"
                                      : "next"
                                  }
                                >
                                  <button
                                    className="btn btn-primary btn-block waves-effect waves-light"
                                    type="submit"
                                  >
                                    Save & Next
                                  </button>
                                </li>
                              </ul>
                            </AvForm>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Form>
                            <Row>
                              <Col>
                                <Card>
                                  <CardBody>
                                    <AvForm
                                      onValidSubmit={this.handleTab3Submit}
                                    >
                                      <Row>
                                        <Col lg="6">
                                          <AvField
                                            name="knowledge_source"
                                            label="How did you get to know about our college?  "
                                            type="select"
                                            required
                                            value={this.props.knowledge_source}
                                          >
                                            <option>--Select--</option>
                                            <option>Newspaper</option>
                                            <option>Internet</option>
                                            <option>Radio</option>
                                            <option>Magazine</option>
                                            <option>Friends</option>
                                            <option>Relatives</option>
                                            <option>Website</option>
                                            <option>Hoardings</option>
                                            <option>TV</option>
                                            <option>Other</option>
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <br />
                                      <CardTitle>
                                        Referral Details (If Any):
                                      </CardTitle>
                                      <hr />
                                      <Row>
                                        <Col lg="6">
                                          <AvField
                                            name="referralname"
                                            label="Name  "
                                            placeholder="Enter Name"
                                            type="text"
                                            errorMessage="Enter Name (Max. 30 Characters)"
                                            validate={{
                                              maxLength: { value: 30 },
                                            }}
                                            value={this.props.ref_name}
                                          />
                                          <AvField
                                            name="mobile"
                                            label="Mobile"
                                            placeholder="Enter Referral's Mobile Number"
                                            type="number"
                                            errorMessage="Enter Referral's Mobile Number"
                                            validate={{
                                              minLength: { value: 10 },
                                              maxLength: { value: 10 },
                                            }}
                                            value={this.props.ref_mobile}
                                          />
                                        </Col>
                                        <Col lg="6">
                                          <AvField
                                            name="code"
                                            label="Code"
                                            placeholder="Enter Referral Code"
                                            type="text"
                                            errorMessage="Enter Referral Name (Max. 15 Characters)"
                                            validate={{
                                              maxLength: { value: 15 },
                                            }}
                                            value={this.props.ref_code}
                                          />
                                          <AvField
                                            name="email"
                                            label="Email"
                                            placeholder="Enter Referral's Email"
                                            type="email"
                                            errorMessage="Enter Valid Email"
                                            value={this.props.ref_email}
                                          />
                                        </Col>
                                      </Row>
                                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                                        <li
                                          className={
                                            this.props.activeTabProgress === 1
                                              ? "previous disabled"
                                              : "previous"
                                          }
                                        >
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              this.openPreviousTab();
                                            }}
                                          >
                                            Previous
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            this.props.activeTabProgress === 4
                                              ? "next disabled"
                                              : "next"
                                          }
                                        >
                                          <button
                                            className="btn btn-primary btn-block waves-effect waves-light"
                                            type="submit"
                                          >
                                            Save & Next
                                          </button>
                                        </li>
                                      </ul>
                                    </AvForm>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                </div>
                                <div>
                                  <h5>Confirm Details</h5>
                                  <p className="text-muted">
                                    I certify that the particulars given by me
                                    in this application form are true to the
                                    best of my knowledge & belief. I have
                                    carefully read the Prospectus of the college
                                    & agree to abide by the terms & conditions
                                    mentioned therein.
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            <li
                              className={
                                this.props.activeTabProgress === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  this.openPreviousTab();
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={
                                "next"
                                // this.props.activeTabProgress === 4
                                //   ? "next disabled" :                              "next"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  this.handleTab4Submit();
                                }}
                              >
                                Submit Form
                              </Link>
                            </li>
                          </ul>
                        </TabPane>
                        <TabPane tabId={5}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                </div>
                                <div>
                                  <h5>Application Form Submitted</h5>
                                  <p className="text-muted">
                                    Your application form has been submitted
                                    successfuly. <br /> Select programs to apply
                                    below and proceed with application fee
                                    payment. <br /> Your application shall be
                                    considered complete only after successful
                                    application fee payment.
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                          <div>
                            <AvForm onValidSubmit={this.handleTab5Submit}>
                              <hr />

                              <Row>
                                <Col lg="6">
                                  <CardTitle>Select Program(s):</CardTitle>
                                  <FormGroup className="mb-0 templating-select select2-container">
                                    <Select
                                      value={this.state.selectedPrograms}
                                      isMulti={true}
                                      onChange={this.handleProgramSelect}
                                      options={programs}
                                      classNamePrefix="select2-selection"
                                      closeMenuOnSelect={false}
                                      components={animatedComponents}
                                      required
                                    />
                                  </FormGroup>
                                  <br />
                                  <CardTitle>
                                    Application Fee (₹):{" "}
                                    {this.state.applicationFee}
                                  </CardTitle>
                                  <br />
                                  <button
                                    className="btn btn-primary btn-block waves-effect waves-light"
                                    type="submit"
                                  >
                                    Proceed to Payment
                                  </button>
                                </Col>
                                <Col lg="6">
                                  <CardTitle>Applied Program(s):</CardTitle>
                                  <BootstrapTable
                                    data={
                                      this.props.programs_applied &&
                                      this.props.programs_applied
                                    }
                                    striped
                                    ignoreSinglePage
                                    responsive
                                    hover
                                  >
                                    <TableHeaderColumn
                                      dataField="program_id"
                                      isKey={true}
                                      width="70"
                                      hidden
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="program_name"
                                      width="140"
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      Program Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="amount"
                                      width="70"
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      Fee
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="date_of_application"
                                      width="100"
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      Application Date
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </Col>
                              </Row>
                            </AvForm>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    activeTab,
    activeTabProgress,
    progressValue,
    loading,
    message,
    subjects,
    ca_address,
    ca_city,
    ca_state,
    ca_country,
    ca_pincode,
    pa_address,
    pa_city,
    pa_state,
    pa_country,
    pa_pincode,
    ref_name,
    ref_code,
    ref_mobile,
    ref_email,
    name,
    parent_mobile,
    gender,
    date_of_birth,
    nationality,
    religion,
    category,
    father_name,
    mother_name,
    knowledge_source,
    qualifying_exam_status,
    qualifications,
    hs_subjects,
    inter_subjects,
    hs_rollno,
    hs_board,
    hs_school,
    hs_city,
    hs_obtained_marks,
    hs_max_marks,
    hs_percentage,
    hs_grade,
    hs_passing_year,
    inter_rollno,
    inter_board,
    inter_school,
    inter_city,
    inter_obtained_marks,
    inter_max_marks,
    inter_percentage,
    inter_grade,
    inter_passing_year,
    programs_applied,
  } = state.ApplicationForm;
  const { programs } = state.Enquiry;
  return {
    activeTab,
    activeTabProgress,
    progressValue,
    progressValue,
    loading,
    message,
    subjects,
    ca_address,
    ca_city,
    ca_state,
    ca_country,
    ca_pincode,
    pa_address,
    pa_city,
    pa_state,
    pa_country,
    pa_pincode,
    ref_name,
    ref_code,
    ref_mobile,
    ref_email,
    name,
    parent_mobile,
    gender,
    date_of_birth,
    nationality,
    religion,
    category,
    father_name,
    mother_name,
    knowledge_source,
    qualifying_exam_status,
    qualifications,
    hs_subjects,
    inter_subjects,
    hs_rollno,
    hs_board,
    hs_school,
    hs_city,
    hs_obtained_marks,
    hs_max_marks,
    hs_percentage,
    hs_grade,
    hs_passing_year,
    inter_rollno,
    inter_board,
    inter_school,
    inter_city,
    inter_obtained_marks,
    inter_max_marks,
    inter_percentage,
    inter_grade,
    inter_passing_year,
    programs,
    programs_applied,
  };
};
export default withRouter(
  connect(mapStatetoProps, {
    submitApplication,
    previousButtonClicked,
    loadApplication,
    loadPrograms,
    apply,
  })(ApplicationForm)
);
