import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//MobileOTP Redux states
import { VERIFY_EMAIL } from "./actionTypes";
import { verifyEmailSuccessful, verifyEmailFailed } from "./actions";

//Include Helper Files with needed methods
import { postSubmitFormNoAuth } from "../../../helpers/forms_helper";

//Submit Enquiry
function* verifyEmail({ payload: { userDetails } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "admissionapplicants/verifyemail";

    const response = yield call(postSubmitFormNoAuth, url, userDetails);

    if (response.status === 1) {
      yield put(verifyEmailSuccessful(response));
    } else {
      yield put(verifyEmailFailed(response));
    }
  } catch (error) {
    yield put(verifyEmailFailed(error));
  }
}

export function* watchVerifyEmail() {
  yield takeEvery(VERIFY_EMAIL, verifyEmail);
}

function* verifyemailSaga() {
  yield all([fork(watchVerifyEmail)]);
}

export default verifyemailSaga;
