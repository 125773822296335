import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";

//MobileOTP Redux states
import {
  SUBMIT_APPLICATION,
  PREVIOUS_BUTTON_CLICKED,
  LOAD_APPLICATION,
  APPLY,
} from "./actionTypes";
import {
  submitApplicationSuccessful,
  submitApplicationFailed,
  previousButtonClickedSuccessful,
  previousButtonClickedFailed,
  loadApplicationSuccessful,
  loadApplicationFailed,
  applySuccessful,
  applyFailed,
} from "./actions";

//Include Helper Files with needed methods
import { postSubmitForm } from "../../helpers/forms_helper";

//Load Application
function* loadApplicationForm({ payload: {} }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "admissionapplicants/get";
    const response = yield call(postSubmitForm, url, null);

    if (response.status === 1) {
      yield put(loadApplicationSuccessful(response));
    } else {
      console.log(response);
      yield put(loadApplicationFailed(response));
    }
  } catch (error) {
    console.log(error);
    yield put(loadApplicationFailed(error));
  }
}

//Submit Application
function* submitApplicationForm({ payload: { applicationData, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "admissionapplicants/updateform";
    const response = yield call(postSubmitForm, url, applicationData);
    const state = yield select();
    response.activeTab = state.ApplicationForm.activeTab;
    response.activeTabProgress = state.ApplicationForm.activeTabProgress;
    response.progressValue = state.ApplicationForm.progressValue;
    response.formData = applicationData;
    if (response.status === 1) {
      yield put(submitApplicationSuccessful(response));
      if (applicationData.form_tab === 4) {
        history.push("/dashboard");
      }
    } else {
      yield put(submitApplicationFailed(response));
    }
  } catch (error) {
    yield put(submitApplicationFailed(error));
  }
}

//Previous Button Clicked
function* previousButtonClicked({ payload: { formTabData } }) {
  try {
    const state = yield select();
    const response = {};
    response.activeTab = state.ApplicationForm.activeTab - 1;
    response.activeTabProgress = state.ApplicationForm.activeTabProgress - 1;
    response.progressValue = state.ApplicationForm.progressValue - 25;

    yield put(previousButtonClickedSuccessful(response));
  } catch (error) {
    yield put(previousButtonClickedFailed(error));
  }
}

//Final Apply Click
function* apply({ payload: { applicationData } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "admissionapplicants/apply";

    const response = yield call(postSubmitForm, url, applicationData);

    if (response.status === 1) {
      yield put(applySuccessful(response));
    } else {
      yield put(applyFailed(response));
    }
  } catch (error) {
    yield put(applyFailed(error));
  }
}
export function* watchApply() {
  yield takeEvery(APPLY, apply);
}

export function* watchLoadApplicationForm() {
  yield takeEvery(LOAD_APPLICATION, loadApplicationForm);
}

export function* watchSubmitApplicationForm() {
  yield takeEvery(SUBMIT_APPLICATION, submitApplicationForm);
}

export function* watchPreviousButtonClicked() {
  yield takeEvery(PREVIOUS_BUTTON_CLICKED, previousButtonClicked);
}

function* applicationformSaga() {
  yield all([
    fork(watchSubmitApplicationForm),
    fork(watchPreviousButtonClicked),
    fork(watchLoadApplicationForm),
    fork(watchApply),
  ]);
}

export default applicationformSaga;
